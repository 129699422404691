.selectedTag, .selectedType{
    text-decoration: underline;
    font-style: italic;
}
.unselectedTag, .unselectedType{

}
Link{
    textDecoration: "none";
    boxShadow: "none";
}
